import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { useConfig } from '../../hooks/useConfig';

export default function TeilnehmerBedingungen() {
  const { 
    config: { 
      solaTitle,
    }, 
  } = useConfig();

  return (
    <Container>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper style={{ height: '90vH', overflow: 'auto', padding: '16px', margin: '8px' }}>
                  <span><b>Liebe Eltern,</b></span>
                  <p>
                    wir freuen uns, dass Ihr (jugendliches) Kind beim SOLA mit dabei sein will. 
                    Früher fuhr man einfach los, heute muss alles x-mal bestätigt und genehmigt werden. Auch bei uns 
                    kommen Sie leider nicht drum herum. &#128533;
                  </p>
                  <p>
                    In unseren Hinweisen und <b><a href="./bedingungen/Anmeldung u.Bedingungen_Zieko.pdf" target="_blank">Bedingungen zur Teilnahme am {solaTitle}</a></b> haben wir im Sinne der 
                    Pauschalreiserechtlinie alle Eckdaten zur Teilnahme am SOLA Zieko zusammengestellt. 
                    <br/>
                    Nehmen Sie diese bitte zur Kenntnis.
                    <br/>
                    Damit erhalten nicht nur „SOLA-Neulinge“ bereits vor der Anmeldung u.a. wichtige Informationen:
                  </p>
                  <ul>
                    <li>zu den Terminen und Altersgruppen,</li>
                    <li>zu den im Preis enthaltenen Leistungen,</li>
                    <li>zum Freizeitprogramm mit möglichen Angeboten, Aufgaben der Teilnehmer, Umgang mit Werkzeug auf dem SOLA,</li>
                    <li>zu den Formalitäten und dem Ablauf der Anmeldung,</li>
                    <li>zu den Teilnehmerbeträgen und der Bezahlung,</li>
                    <li>zur Unterbringung und zu sanitären Anlagen,</li>
                    <li>zur Betreuung und pädagogischen Konzeption (Smartphones u.ä. sind z.B. nicht gestattet)</li>
                    <li>zu Verboten des Besitzes von Drogen aller Art und gefährlichen Gegenständen,</li>
                    <li>an die Eltern, Ihr Kind eine Woche lang ohne Telefonat und Besuch dem Thema und seiner SOLA-Gruppe zu überlassen  &#128522;,</li>
                    <li>zur Aufsicht,</li>
                    <li>zu Voraussetzungen und Möglichkeiten der (hoffentlich nicht notwendigen) Absage des SOLA,</li>
                    <li>zu ggf. notwendigen Abmeldungen oder vorzeitigen Abreisen, </li>
                    <li>zu Bildveröffentlichungen,</li>
                    <li>zum Freizeitpass und den Angaben zur Gesundheit, zum Verhalten und anderen Besonderheiten, </li>
                    <li>zum Datenschutz, Veranstalter, zur Leitung sowie zu den Kontaktdaten.</li>
                  </ul>
                  <p>
                    Weiter enthalten ist das Formblatt für Pauschalreisen sowie das Merkblatt zum Infektionsschutz.
                  </p>
                  <p>
                    Mit der Anmeldung stimmen Sie diesen <b><a href="./bedingungen/Anmeldung u.Bedingungen_Zieko.pdf" target="_blank">Reisebedingungen</a></b> inkl. der Speicherung Ihrer Anmeldedaten ausdrücklich zu. 
                    <br/>
                    Bitte beachten Sie auch, dass wir uns im Falle eines Rücktritts die Einbehaltung einer Bearbeitungsgebühr vorbehalten. 
                    <br/>
                    Ein Hinweis für „alte Hasen“! Es gibt einige Änderungen bzw. Aktualisierungen gegenüber den Vorjahren.
                  </p>
                  <p>
                    Sollte es wegen offizieller Auflagen oder aufgrund zu geringer Teilnehmer- und/ oder Mitarbeiterzahlen kein „normales“ SOLA geben können, erhalten Sie die Anzahlung nach Bekanntgabe Ihrer Kontoverbindung innerhalb von 14 Tagen zurück. Sie gehen mit der Anmeldung somit kein finanzielles Risiko ein.
                  </p>
                  <p>
                    Bei Teilnahme an einem Alternativangebot des SOLA wird die geleistete Anzahlung mit dem Teilnehmerbetrag dafür verrechnet.
                  </p>
                  <p>
                    Sollten Sie noch Fragen haben, wenden Sie sich bitte an Helma Mühlmann unter Tel. 034903 / 496159
                    oder per mail an helma-muehlmann@solazieko.de. Wir versuchen, alle Fragen zu klären und 
                    ggf. individuelle Lösungen zu finden.
                  </p>
                  <p>
                    Im folgenden Formular fragen wir alle für uns notwendigen Daten und Informationen ab. Diese 
                    werden vertraulich behandelt und sind entsprechend den o.g. Reisebedingungen nur für die Organisation und Verwaltung des SOLA Zieko bestimmt.
                  </p>
                </Paper>
            </Grid>
        </Grid>
    </Container>
  );
}