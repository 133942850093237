import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import FlyerButton from './welcomePage/FlyerButton';

import { usePersons } from '../../hooks/usePersons';
import { useConfig } from '../../hooks/useConfig';

const useStyles = makeStyles((theme) => ({
  graySection: {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(3,0),
  },
  margin: {
    margin: theme.spacing(2),
  },
}));

export default function Sola() {
  const classes = useStyles();
  const { coords } = usePersons();
  const { 
    config: { 
        teiliRegistrationEnabled,
        mitarbeiterRegistrationEnabled,
        solaTitle,
        solaThemeTitle,
        solaThemeRegistration,
        solaKidsTermin,
        kidsPrice,
        solaTeensTermin,
        teensPrice,
        solaEndBewerbungTermin,
    }, 
  } = useConfig();

  return (
    <>
      <div className={classes.graySection}>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">SOMMERLAGER ZIEKO - {solaTitle}</Typography>
              <Typography variant="h6">{solaThemeTitle}</Typography>
            </Grid>
            {
              Boolean(teiliRegistrationEnabled) || Boolean(mitarbeiterRegistrationEnabled)
              ? (
                <Grid item xs={12}>
                  <Typography variant="body1">{solaThemeRegistration}</Typography>
                  {
                    Boolean(teiliRegistrationEnabled) 
                    ? (
                      <Button variant="contained" color="primary" href={'#teilnehmer'} className={classes.margin}>
                        Anmeldung Teilnehmer
                      </Button>
                    )
                    : <></>
                  }

                  {
                    Boolean(mitarbeiterRegistrationEnabled) 
                    ? (
                      <Button variant="contained" color="primary" href={'#mitarbeiter'} className={classes.margin}>
                        Anmeldung Mitarbeiter
                      </Button>
                    )
                    : <></>
                  }
                </Grid>
              )
              : <></>
            }

            <Grid item xs={12} sm={6}>
              <Typography variant="h5">TEENS</Typography>
              <Typography variant="body1">WER? 13 bis 17 Jahre</Typography>
              <Typography variant="body1">WANN? {solaTeensTermin}</Typography>
              <Typography variant="body1">PREIS? {teensPrice}€</Typography>
              <br/>
              <Typography variant="body2">bei Anmeldung oder Zahlung nach dem {solaEndBewerbungTermin} jeweils 15€ mehr</Typography>
              <Typography variant="body2">30€ Geschwisterrabatt ab dem 2. Kind, ab dem 3. Kind je 50€ </Typography>
              {
                coords.startingTeens && 
                <>
                  <br/>
                  <Typography variant="h6">Startpunkt Teens:</Typography>
                  <div dangerouslySetInnerHTML={{ __html: `<iframe style="height:400px; width: 100%;border-radius: 8px;" frameborder="0" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?f=q&amp;source=s_d&amp;hl=de&amp;saddr=${coords.startingTeens}&amp;z=15&amp;output=embed"></iframe>` }} />
                </>
              }
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">KIDS</Typography>
              <Typography variant="body1">WER? 9 bis 13 Jahre</Typography>
              <Typography variant="body1">WANN? {solaKidsTermin}</Typography>
              <Typography variant="body1">PREIS? {kidsPrice}€</Typography>
              <br/>
              <Typography variant="body2">bei Anmeldung oder Zahlung nach dem {solaEndBewerbungTermin} jeweils 15€ mehr</Typography>
              <Typography variant="body2">30€ Geschwisterrabatt ab dem 2. Kind, ab dem 3. Kind je 50€ </Typography>
              {
                coords.startingKids && 
                <>
                  <br/>
                  <Typography variant="h6">Startpunkt Kids:</Typography>
                  <div dangerouslySetInnerHTML={{ __html: `<iframe style="height:400px; width: 100%;border-radius: 8px;" frameborder="0" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?f=q&amp;source=s_d&amp;hl=de&amp;saddr=${coords.startingKids}&amp;z=15&amp;output=embed"></iframe>` }} />
                </>
              }
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">Veranstalter: Ev. Hoffnungsgemeinde Zieko | Dorfstraße 2 | OT Zieko - 06869 Coswig/Anhalt | Tel. 034903/496159</Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <FlyerButton/>
    </>
  );
}
